
import { Options, Vue } from 'vue-class-component';
import YogagaStreamList from './components/YogagaStreamList.vue';
import LiveStreamVideo from './components/LiveStreamVideo.vue';

@Options({
  components: {
    YogagaStreamList,
    LiveStreamVideo
  },
})
export default class App extends Vue {}
