
import { Options, Vue } from 'vue-class-component';
import dateTime from 'date-and-time';

@Options({
  props: {
    url: String,
    token: String
  }
})
export default class YogagaStreamList extends Vue {

    public readonly url!: string;
    public readonly token!: string;
    private products: any[] = [];
  $session: any;

    public mounted() {

        const token = (new URLSearchParams(window.location.search)).get('token');

        fetch( this.url + '/' + token, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.token
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);

                this.$session.streams = data;
                this.products = data;
            })
            .catch(error => {
                console.log(error);
            });
    }

    //Format time to d.m.Y H:i
    public formatTime(time: string){
        return dateTime.format(new Date( parseInt(time) * 1000), 'DD.MM.YYYY HH:mm');
    }
}
