import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_live_stream_video = _resolveComponent("live-stream-video")!
  const _component_yogaga_stream_list = _resolveComponent("yogaga-stream-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_live_stream_video, { style: {"margin-bottom":"0.25vw"} }),
    _createVNode(_component_yogaga_stream_list, { url: "https://yogaga.de/wp-json/yogaga/v1/stream" })
  ], 64))
}